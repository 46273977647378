import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FavoritesEffects } from './+state/favorites.effects';
import { favoritesFeature } from './+state/favorites.reducer';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(favoritesFeature), EffectsModule.forFeature([FavoritesEffects])]
})
export class FavoritesDataAccessModule {}
