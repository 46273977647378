import { IProductoModeloCatalogo } from '@fgp/product/interfaces';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { FavoriteActions } from './favorites.actions';
import { FavoriteState } from './favorites.state';

export const favoritesAdapter: EntityAdapter<IProductoModeloCatalogo> = createEntityAdapter<IProductoModeloCatalogo>({
  selectId: (favoriteEntity: IProductoModeloCatalogo) => favoriteEntity?.productoPrincipal?.idProducto
});

const initialState: FavoriteState = favoritesAdapter.getInitialState({
  favoriteId: null,
  error: null
});

export const favoritesFeature = createFeature({
  name: 'favorites',
  reducer: createReducer(
    initialState,
    on(FavoriteActions.initData, (state, { favoriteId }) => ({ ...state, favoriteId })),
    on(FavoriteActions.loadSuccess, (state, { favorites }) => favoritesAdapter.setAll(favorites, state)),
    on(FavoriteActions.toggleData, (state, { product }) => favoritesAdapter.upsertOne({ ...product, clicked: true }, state)),
    on(FavoriteActions.toggleSuccess, (state, { favorites, favoriteId }) =>
      favoritesAdapter.setAll(favorites, { ...state, favoriteId, favoriteClicked: false })
    )
  ),
  extraSelectors: ({ selectFavoritesState }) => ({
    ...favoritesAdapter.getSelectors(selectFavoritesState),
    selectTotal: createSelector(favoritesAdapter.getSelectors(selectFavoritesState).selectTotal, total => {
      return total <= 0 ? null : total <= 9 ? `${total}` : '+9';
    }),
    selectIsFavorite: (productId: string) =>
      createSelector(favoritesAdapter.getSelectors(selectFavoritesState).selectEntities, entities => ({
        isFavorite: entities[productId] !== undefined,
        clicked: entities[productId]?.clicked ?? false
      }))
  })
});

const { name, reducer, ...selectors } = favoritesFeature;
export const FavoritesSelectors = selectors;
