import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@fgp/app-config';
import { IServicioProductFavorite } from '@fgp/favorites/interfaces';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {
  private urlApiProductFavoritesPublica = `${this.appConfig.apiPublicUrl}online/favoritos`;

  constructor(@Inject(APP_CONFIG) private appConfig, private http: HttpClient) {}

  addRemoveFavorite(idFavorito: string, idProducto: string) {
    return this.http.post<IServicioProductFavorite>(`${this.urlApiProductFavoritesPublica}/addOrRemove/${idFavorito}/${idProducto}`, null);
  }

  loadFavorites(favoriteId: string) {
    return this.http.get<IServicioProductFavorite>(`${this.urlApiProductFavoritesPublica}/${favoriteId}`);
  }
}
