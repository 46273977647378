import { inject, Injectable } from '@angular/core';
import { selectUrl } from '@fgp/shared/router-state';
import { AdminSessionService } from '@fgp/shared/services/admin-session';
import { GtmEventsService } from '@fgp/shared/services/gtm-events';
import { ValidarResponse } from '@fgp/utils/validate-response';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { exhaustMap, filter, map } from 'rxjs';
import { FavoritesService } from '../favorites.service';
import { FavoriteActions } from './favorites.actions';
import { FavoritesSelectors } from './favorites.reducer';

@Injectable()
export class FavoritesEffects implements OnInitEffects {
  private gtmService = inject(GtmEventsService);

  init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FavoriteActions.initData),
      concatLatestFrom(() => this.store.select(FavoritesSelectors.selectFavoriteId)),
      filter(([, favoriteId]) => favoriteId !== null),
      exhaustMap(([{ favoriteId }]) =>
        this.favoritesService.loadFavorites(favoriteId).pipe(
          map(({ content: { productosFavoritos } }) => {
            return FavoriteActions.loadSuccess({
              favorites: productosFavoritos.map(favorite => {
                return { ...favorite, clicked: false };
              })
            });
          })
        )
      )
    );
  });

  toggle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FavoriteActions.toggleData),
      concatLatestFrom(() => [this.store.select(FavoritesSelectors.selectFavoriteId), this.store.select(selectUrl)]),
      exhaustMap(
        ([
          {
            product: {
              productoPrincipal: { idProducto, nombre }
            }
          },
          favoriteId,
          url
        ]) =>
          this.favoritesService.addRemoveFavorite(favoriteId, idProducto).pipe(
            map(response => {
              if (ValidarResponse.validar(response)) {
                response.content.productosFavoritos.some(p => p?.productoPrincipal?.idProducto === idProducto) &&
                  this.gtmService.wishListEvent(url, nombre);
                !this.adminSessionService.getFavoritos() && this.adminSessionService.setFavoritos(response.content.idAES);
                return FavoriteActions.toggleSuccess({
                  favorites: response.content.productosFavoritos,
                  favoriteId: response.content.idAES
                });
              }
              return FavoriteActions.toggleFailure({ error: response.code });
            })
          )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private adminSessionService: AdminSessionService,
    private store: Store,
    private favoritesService: FavoritesService
  ) {}

  ngrxOnInitEffects(): Action {
    return FavoriteActions.initData({ favoriteId: this.adminSessionService.getFavoritos() });
  }
}
