import { IProductoModeloCatalogo } from '@fgp/product/interfaces';
import { createActionGroup, props } from '@ngrx/store';

const Favorite = createActionGroup({
  source: 'Favorite',
  events: {
    'Init Data': props<{ favoriteId: string }>(),
    'Toggle Data': props<{ product: IProductoModeloCatalogo }>()
  }
});

const FavoriteApi = createActionGroup({
  source: 'Favorite/API',
  events: {
    'Load Failure': props<{ error: string }>(),
    'Load Success': props<{ favorites: IProductoModeloCatalogo[] }>(),
    'Toggle Failure': props<{ error: string }>(),
    'Toggle Success': props<{ favoriteId: string; favorites: IProductoModeloCatalogo[] }>()
  }
});

export const FavoriteActions = {
  ...Favorite,
  ...FavoriteApi
};
